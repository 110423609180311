import { useSelector } from "react-redux";
import background from "../../../img/new/backgrounds/events-banner.png";

const EventsBanner = ({ mailto }) => {
    const { copy } = useSelector((state) => state.preference);

    return (
        <section className="banner-16 bg_img oh bottom_left" style={{ backgroundImage: "url(" + background + ")" }}>
            <div className="container">
                <div className="row justify-content-center text-center">
                    <div className="col-lg-8 mb-40">
                        <div className="banner-content-3">
                            <h1 className="title">{copy.events.banner.title}</h1>
                            <div className="banner-button-group mt-2 justify-content-center">
                                <a href={mailto} className="button-4">{copy.events.banner.cta}</a>
                                <a href="#go" onClick={() => document.getElementById('how').scrollIntoView({ behavior: 'smooth' })} className="play-button popup">
                                    <span className="cl-black">{copy.events.banner.know}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EventsBanner;