import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const eventsApi = createApi({
    reducerPath: "events",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.NODE_ENV === "production" ? process.env.CLIENT : "http://localhost:3000"
    }),
    endpoints(builder) {
        return {
            fetchEvent: builder.query({
                query: (eventName) => {
                    return {
                        url: `/api/participants/${eventName}`,
                        method: 'GET'
                    }
                },
                providesTags: ['participants']
            }),
        }
    }
});

export const {
    useFetchEventQuery
} = eventsApi;
export { eventsApi };