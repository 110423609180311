import ReactPixel from 'react-facebook-pixel';
import { Field, Form } from "react-final-form";
import { Anchor, Box, Button, FormField, Form as GrommetForm, Image, Paragraph, Spinner, Text } from "grommet";
import { useNavigate, useParams } from "react-router-dom";
import { useConfigureProfileMutation, useFetchProfileQuery, useFetchPassUrlQuery, useUpdatePassUrlMutation } from "../store";
import img from "../languages/img";
import { useSelector } from "react-redux";
import illustration from "../img/new/illustrations/config.png";
import NiceError from "./NiceError";
import Loader from "./Loader";
import { useEffect, useState } from "react";
import { registerClick } from "./registeredClick";
// import AlsoSeen from "./AlsoSeen";

const AccountField = ({ field, data }) => {
    if (!(data.fullname && field.name === "fullname")) {
        return (
            <FormField
                key={field.name}
                className="no-border account-form"
            >
                <Field
                    name={field.name}
                    validate={field.required ? (value => (value ? undefined : field.errorMessage)) : undefined}
                >
                    {subprops => (
                        <div className="form-group">
                            <label htmlFor="sign-up">{field.required ? field.label + ' *' : field.label}</label>
                            < input type="text" placeholder={field.placeholder} {...subprops.input} />
                        </div>
                    )}
                </Field>
                <Field
                    name={field.name}
                    subscription={{ touched: true, error: true }}
                    render={({ meta: { touched, error } }) =>
                        touched && error ? <Box pad="medium"><Text size="xsmall" color="brand">{error}</Text></Box> : null
                    }
                />
            </FormField>
        )
    }
}

const AddToWallet = ({ sessionId, color }) => {
    const { data, error, isLoading, isFetching } = useFetchPassUrlQuery({ sessionId, color });

    if (isLoading || isFetching) {
        return (<Box align="center" margin={{ top: "54px", bottom: "40px" }} gap="15px"><Spinner /></Box>)
    } else if (error) {
        return <NiceError component="AddToWallet" error={error} />
    } else if (data) {
        return (
            <Box align="center" margin={{ top: "24px", bottom: "24px" }} gap="15px">
                <Anchor
                    href={"https://nice2meet-green.herokuapp.com/download/pass/" + sessionId + '/' + color}
                    target="_blank"
                    onClick={() => registerClick('download', 'Apple_wallet', sessionId)}
                >
                    <Image
                        src={img.appleWallet}
                        srcSet={`${img.appleWallet2x} 2x`}
                        width="232px"
                        height="61px"
                    />
                </Anchor>
                <Anchor
                    href={data.url}
                    target="_blank"
                    onClick={() => registerClick('download', 'Google_wallet', sessionId)}
                >
                    <Image
                        src={img.googleWallet}
                        srcSet={`${img.googleWallet2x} 2x`}
                        width="264px"
                        height="61px"
                    />
                </Anchor>
            </Box>
        )
    }
}

const AccountButtons = ({ props, data, color, ...rest }) => {
    const navigate = useNavigate();
    const { copy } = useSelector((state) => state.preference);

    return (
        <Box {...rest}>
            {props.touched && props.hasValidationErrors &&
                <Paragraph color="brand" alignSelf="center">{copy.configure.verify}</Paragraph>
            }
            {((props.dirty || props.modifiedSinceLastSubmit) && (!props.pristine)) ?
                <>
                    <Paragraph color="brand" alignSelf="center">{copy.configure.save}</Paragraph>
                    <Button className="cta" type="submit" size="large" primary label={copy.configure.generate} disabled={props.submitting || props.pristine || !props.valid} />
                </>
                :
                <AddToWallet sessionId={data.sessionId} color={color} />
            }
            <br />
            {(props.submitSucceeded || (props.pristine && props.values.companyName)) &&
                <Box>
                    <Text size="small" textAlign="center" style={{ zIndex: "10" }}>{copy.configure.see}</Text>
                    <div className="form-group" style={{ marginBottom: "-1rem" }}>
                        <button
                            onClick={() => navigate('/iam/' + data.username)}
                            style={{ background: "-webkit-linear-gradient(-65deg,#c165dd 0,#1153fc 100%)", color: "#FFFFFF" }}
                        >
                            nice2meet.io/iam/<strong>{data.username}</strong>
                        </button>
                    </div>
                </Box>
            }
        </Box>
    )
}

const ConfigForm = () => {
    const { copy } = useSelector((state) => state.preference);

    const { sessionId } = useParams();
    const [configureProfile] = useConfigureProfileMutation();
    const [updatePassUrl] = useUpdatePassUrlMutation();
    const { data, error, isLoading } = useFetchProfileQuery(sessionId);
    const [color, setColor] = useState('purple');
    const handleSubmit = async (values) => {
        await configureProfile({ sessionId, ...values });
        registerClick('config', 'submitted_change', values.sessionId)
    }

    useEffect(() => {
        const advancedMatching = { external_id: sessionId };
        const options = {
            autoConfig: true,
            debug: false,
        };
        ReactPixel.init('601146662084370', advancedMatching, options);
        ReactPixel.track('Purchase', {
            value: 14.99,
            currency: 'BRL',
        })
    }, [sessionId])

    const changeColor = (value) => {
        setColor(value);
        updatePassUrl({ sessionId, value });
        registerClick('config', 'changed_color', value);
    }

    if (isLoading) {
        return <Loader />
    } else if (error) {
        return <NiceError component="ConfigForm" error={error} />
    } else if (data) {
        localStorage.setItem('sessionId', data.sessionId);
        return (
            <Form
                onSubmit={handleSubmit}
                keepDirtyOnReinitialize={true}
                initialValues={data}
                render={(props) => {
                    return (
                        <GrommetForm onSubmit={(event) => props.handleSubmit(event)}>


                            <div className="row justify-content-center justify-content-lg-between">
                                <div className="col-lg-7">
                                    <div className="contact-wrapper">
                                        <h4 className="title text-center mb-30">{copy.configure.color}</h4>
                                        <Box align="center" justify="center" style={{ marginBottom: "32px" }}>
                                            <Image
                                                src={img[color + "Card"]}
                                                srcSet={`${img[color + "Card2x"]} 2x`}
                                                width="309px"
                                                height="195px"
                                                style={{ marginBottom: "16px" }}
                                            />
                                            <Box direction="row" gap="medium">
                                                <Button primary color="#7000CC" label={copy.configure.colors[0]} onClick={() => changeColor('purple')} active={color === 'purple'} />
                                                <Button primary color="white" label={copy.configure.colors[1]} onClick={() => changeColor('light')} active={color === 'light'} />
                                                <Button primary color="black" label={copy.configure.colors[2]} onClick={() => changeColor('dark')} active={color === 'dark'} />
                                            </Box>
                                        </Box>
                                        <div className="or">
                                            <span>{copy.configure.data}</span>
                                        </div>
                                        <div className="mt-15" style={{ textAlign: 'revert' }} >
                                            {data.fullname && <h4 className="title mt-15">{data.fullname}</h4>}
                                            {copy.configure.fields.map((field, i) => <AccountField key={i} field={field} data={data} />)}
                                        </div>
                                        <AccountButtons props={props} data={data} color={color} />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="contact-content">
                                        <div className="man d-lg-block d-none">
                                            <img src={illustration} alt="bg" />
                                        </div>
                                        <div className="section-header left-style">
                                            <h3 className="title">{copy.configure.questions}</h3>
                                            <p>{copy.configure.support}</p>
                                            <AccountButtons props={props} data={data} color={color} className="d-lg-block d-none mb-50" />
                                            {/* <Box style={{ marginTop: "100px" }}>
                                                <AlsoSeen />
                                            </Box> */}
                                        </div>
                                    </div>
                                </div>
                            </div>





                        </GrommetForm>
                    )
                }}
            />
        )
    }
}

export default ConfigForm;