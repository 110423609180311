import { useSelector } from "react-redux";
import feature01 from "../../../img/new/icons/events-feature01.png";
import feature02 from "../../../img/new/icons/events-feature02.png";
import feature03 from "../../../img/new/icons/events-feature03.png";
import feature04 from "../../../img/new/icons/events-feature04.png";
import { registerClick } from "../../../components/registeredClick";

const EventsFeaturesList = ({ mailto }) => {
    const { copy } = useSelector((state) => state.preference);

    return (
        <section className="feature-section padding-top-2 padding-bottom-2" id="how">
            <div className="container">
                <div className="feature--wrapper-17">
                    <div className="row align-items-center flex-wrap-reverse position-relative">
                        <div className="col-lg-6">
                            <div className="row mb-30-none">
                                <div className="col-md-6">
                                    <div className="am-item">
                                        <div className="am-thumb">
                                            <img src={feature01} alt="feature" />
                                        </div>
                                        <div className="am-content">
                                            <h5 className="title">{copy.events.how.features.a}</h5>
                                        </div>
                                    </div>
                                    <div className="am-item active">
                                        <div className="am-thumb">
                                            <img src={feature02} alt="feature" />
                                        </div>
                                        <div className="am-content">
                                            <h5 className="title">{copy.events.how.features.b}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-md-120">
                                    <div className="am-item">
                                        <div className="am-thumb">
                                            <img src={feature03} alt="feature" />
                                        </div>
                                        <div className="am-content">
                                            <h5 className="title">{copy.events.how.features.c}</h5>
                                        </div>
                                    </div>
                                    <div className="am-item">
                                        <div className="am-thumb">
                                            <img src={feature04} alt="feature" />
                                        </div>
                                        <div className="am-content">
                                            <h5 className="title">{copy.events.how.features.d}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="section-header left-style mb-lg-0">
                                <h5 className="cate">{copy.events.how.subtitle}</h5>
                                <h2 className="title">{copy.events.how.title}</h2>
                                {copy.events.how.paragraphs.map((paragraph, i) => <p key={i}>{paragraph}</p>)}
                                <a href={mailto} className="hightlight-link" onClick={() => registerClick('events', 'interest', copy.events.how.cta)}>{copy.events.how.cta} <i className="flaticon-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >

    )
}

export default EventsFeaturesList;