import background from "../img/new/backgrounds/download.png";
import screenshot from "../img/new/screenshots/download.png";
import { useSelector } from "react-redux";
import { registerClick } from "./registeredClick";
import { Box } from "grommet";
import DynamicPrice from "./DynamicPrice";

const DownloadSection = () => {
    const { copy } = useSelector((state) => state.preference);

    return (
        <section className="apps-download-section overflow-hidden padding-top padding-bottom body--bg">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-9">
                        <div className="section-header mw-100">
                            <h5 className="cate">{copy.home.download.subtitle}</h5>
                            <h2 className="title">{copy.home.download.title}</h2>
                            <p className="mx-auto mw-540px">
                                {copy.home.download.paragraph}
                            </p>
                            <Box justify="center" align="center" fill="horizontal">
                                <DynamicPrice/>
                            </Box>
                        </div>
                    </div>
                </div>
                <div className="apps-download-buttons-20">
                    <h6 className="title cl-white text-center mb-4">{copy.home.download.cta}</h6>
                    <ul className="download-options"
                    onClick={() => window.open(copy.home.prices.annually[0].url, "_self")}
                    style={{ cursor: "pointer" }}
                    >
                        <li>
                            <a href={copy.home.prices.annually[0].url} className="active" onClick={() => registerClick('checkout', 'download_section', 'iPhone')}><i className="fab fa-apple"></i></a>
                            <span className="cl-white">iPhone</span>
                        </li>
                        <li>
                            <a href={copy.home.prices.annually[0].url} onClick={() => registerClick('checkout', 'download_section', 'Android')}><i className="fab fa-android"></i></a>
                            <span className="cl-white">Android</span>
                        </li>
                    </ul>
                </div>
                <div className="apps-download-screen-20">
                    <div className="apps-download-bg">
                        <img src={background} alt="extra-2/screenshot" />
                    </div>
                    <div className="apps-download-thumb">
                        <img src={screenshot} alt="extra-2/screenshot" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default DownloadSection;