import { Anchor, Box, Button } from "grommet";
// import background from "../img/new/backgrounds/profile.jpg";
import logo from "../img/new/logo/logo.svg";
import valor from "../img/new/logo/valor_white.svg";
import powered from "../img/new/logo/poweredby_black.svg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { registerClick } from "./registeredClick";
import { setSeen } from "../store";
// import AlsoSeen from "./AlsoSeen";
// import illustration from "../img/new/illustrations/alsoSeen.png";

const ProfileSection = ({ data }) => {
    const sessionId = localStorage.getItem('sessionId');

    const { copy } = useSelector((state) => state.preference);
    const dispatch = useDispatch();

    let fixedImg = "fixed-img";
    let img = logo;
    let href = "/";

    if (!!data.eventName && (data.eventName === "valor-crypto-workshop-sao-paulo-2023")) {
        fixedImg = "fixed-img-valor";
        img = valor;
        href = "https://valorcapitalgroup.com/"
    }

    useEffect(() => {
        registerClick("profile", "seen_profile", sessionId);
        dispatch(setSeen(data.sessionId));
    }, [data.sessionId, dispatch, sessionId])

    return (
        <div className={"account-section bg_img " + fixedImg}>
            <div className="container">
                <div className="account-title text-center">
                    {sessionId && <a href={"/create/" + sessionId} className="back-home"><i className="fas fa-angle-left"></i><span>{copy.menu.edit} <span className="d-none d-sm-inline-block">{copy.menu.profile}</span></span></a>}
                    <a href={href} className="logo">
                        <img src={img} alt="logo" width="100px" />
                    </a>
                </div>
                <div className="account-wrapper">
                    <div className="account-header">
                        <h4 className="title">{data.fullname}</h4>
                    </div>
                    <div className="account-body">
                        <span className="d-block mb-10">{data.jobTitle}</span>
                        <span className="d-block mb-20"><strong>{data.companyName}</strong></span>
                        <Box gap="medium" fill="horizontal" pad="medium">
                            {data.phoneNumber && <Button onClick={() => registerClick('accessed', 'Whatsapp', null)} className="center" primary size="large" label="WhatsApp" color="#25D366" href={"https://wa.me/" + data.phoneNumber.replaceAll(/\D/g, "") + "?text=Nice meeting you!"} target="_blank" />}
                            {data.calendly && <Button onClick={() => registerClick('accessed', 'Calendly', null)} className="center" primary size="large" label="Calendly" color="#006BFF" href={data.calendly} target="_blank" />}
                            {data.linkedin && <Button onClick={() => registerClick('accessed', 'Linkedin', null)} className="center" primary size="large" label="LinkedIn" color="#0072b1" href={data.linkedin} target="_blank" />}
                            {data.twitter && <Button onClick={() => registerClick('accessed', 'Twitter', null)} className="center" primary size="large" label="Twitter" color="#00acee" href={data.twitter} target="_blank" />}
                            {data.facebook && <Button onClick={() => registerClick('accessed', 'Facebook', null)} className="center" primary size="large" label="Facebook" color="#3b5998" href={data.facebook} target="_blank" />}
                            {data.instagram && <Button onClick={() => registerClick('accessed', 'Instagram', null)} className="center" primary size="large" label="Instagram" color="#d62976" href={data.instagram} target="_blank" />}
                            {data.website && <Button onClick={() => registerClick('accessed', 'Website', null)} className="center" size="large" label="Website" href={data.website} target="_blank" />}
                            {data.email && <Button onClick={() => registerClick('accessed', 'Email', null)} className="center" size="large" label="Email" href={"mailto:" + data.email} target="_blank" />}
                            {data.calendly && <Button onClick={() => registerClick('accessed', 'Calendly', null)} className="center" size="large" label={copy.menu.meeting} href={data.calendly} target="_blank" />}
                            <Button onClick={() => registerClick('accessed', 'vcf', null)} className="center" size="large" label={copy.menu.vcf} href={"/download/vcf/" + data.sessionId} target="_blank" />
                            <Anchor onClick={() => registerClick('accessed', 'Create_yours', null)} className="center" size="small" href="/">{copy.menu.create}</Anchor>
                        </Box>
                <Box fill="horizontal" align="center" style={{ opacity: 0.2, marginBottom: "-50px", marginTop: "20px" }} onClick={() => window.open('/')}>
                    <img src={powered} width="150px" alt="powered by nice2meet.io"/>
                </Box>
                    </div>
                </div>
                {/* <div className="account-wrapper transparent">
                    <div className="client-item-16 ff-heading horizontal-fill no-margin">
                        <div className="client-content">
                            <AlsoSeen className="widget no-margin horizontal-fill" />
                        </div>
                    </div>
                    <Box fill="horizontal" align="center" className="mt-50">
                        <img src={illustration} width="153px" height="135px" />
                    </Box>
                </div> */}
            </div>
        </div>
    )
}

export default ProfileSection;