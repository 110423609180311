import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const profileApi = createApi({
    reducerPath: "profile",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.NODE_ENV === "production" ? process.env.CLIENT : "http://localhost:3000"
    }),
    endpoints(builder) {
        return {
            fetchProfile: builder.query({
                query: (sessionId) => {
                    return {
                        url: `/api/profile/data/${sessionId}`,
                        method: 'GET'
                    }
                },
                providesTags: ["profile"]
            }),
            fetchSeen: builder.query({
                query: (seen) => {
                    return {
                        url: `/api/profile/seen/`,
                        method: 'POST',
                        body: seen
                    }
                },
                providesTags: ["profile"]
            }),
            fetchUserPage: builder.query({
                query: (username) => {
                    return {
                        url: `/api/profile/data/iam/${username}`,
                        method: 'GET'
                    }
                },
                providesTags: ["profile"]
            }),
            configureProfile: builder.mutation({
                query: ({ sessionId, ...rest }) => {
                    return {
                        url: `/api/profile/update/${sessionId}`,
                        method: 'POST',
                        body: rest
                    }
                },
                invalidatesTags: ["profile"]
            }),
        }
    }
});

export const {
    useFetchProfileQuery,
    useFetchSeenQuery,
    useFetchUserPageQuery,
    useConfigureProfileMutation,
} = profileApi;
export { profileApi };