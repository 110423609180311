import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const androidApi = createApi({
    reducerPath: "android",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.NODE_ENV === "production" ? process.env.CLIENT : "http://localhost:3000"
    }),
    endpoints(builder) {
        return {
            fetchPassUrl: builder.query({
                query: ({ sessionId, color }) => {
                    return {
                        url: `/api/download/android-pass/${sessionId}/${color}`,
                        method: 'GET'
                    }
                },
                providesTags: ["passUrl"]
            }),
            updatePassUrl:builder.mutation({
                query: ({ sessionId, color }) => {
                    return {
                        url: `/api/download/android-pass/${sessionId}/${color}`,
                        method: 'GET'
                    }
                },
                providesTags: ["passUrl"]
            }),
        }
    }
});

export const {
    useFetchPassUrlQuery,
    useUpdatePassUrlMutation
} = androidApi;
export { androidApi };