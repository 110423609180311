import background from "../img/new/backgrounds/page-header-1.png";
import shape from "../img/new/backgrounds/page-header-2.png";

const PageHeader = () => {
    return (
        <section className="page-header single-header bg_img oh" style={{backgroundImage: "url(" + background + ")"}}>
            <div className="bottom-shape d-none d-md-block">
                <img src={shape} alt="css" />
            </div>
        </section>
    )
}

export default PageHeader;