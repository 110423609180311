import BannerSection from "../components/BannerSection";
import FeatureSection from "../components/FeatureSection";
import HeaderSection from "../components/HeaderSection";
import HowSection from "../components/HowSection";
import LogoSliderSection from "../components/LogoSliderSection";
import ColorSection from "../components/ColorSection";
import DownloadSection from "../components/DownloadSection";
import FooterSection from "../components/FooterSection";
import PromoLayer from "../components/PromoLayer";

import url from '../languages/url';
import { registerClick } from '../components/registeredClick';
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const HomePage = ({ buyButtonId }) => {
    const { id } = useParams();
    const luck = id ? id : 5;

    useEffect(() => {
        const the_price = url.prices[luck].price;
        const the_url = url.prices[luck].url;

        localStorage.setItem('price', the_price);
        localStorage.setItem('url', the_url);
        registerClick('price', 'seen_price_' + the_price.replace(/\D/g, ''), the_price);

        window.history.replaceState(null, null, "/");
    }, [luck])

    return (
        <>
            {buyButtonId && <PromoLayer buyButtonId={buyButtonId} />}
            <HeaderSection />
            <BannerSection />
            <LogoSliderSection />
            <FeatureSection />
            <HowSection />
            <ColorSection />
            <DownloadSection />
            <FooterSection />
        </>
    )
}

export default HomePage;