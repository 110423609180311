import React, { useEffect } from 'react';
import ReactGA from "react-ga4";

import "./styles.css";

import { Grommet } from 'grommet';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import HomePage from './screens/HomePage';
import AccountPage from './screens/AccountPage';

import { ProfileFromSessionId, ProfileFromUsername } from './screens/ProfilePage';
import EventsPage from './screens/EventsPage';
import { useDispatch } from 'react-redux';
import { setLanguage } from './store';
import EventsQrCodes from './screens/EventsQrCodes';

ReactGA.initialize("G-XMZDD07FEV");

const App = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (window.location.pathname.includes('//')) {
            window.history.replaceState(null, null, window.location.pathname.replace('//', '/'));
            window.location.reload();
        }
    }, [])

    useEffect(() => {
        if (navigator.language.includes('pt')) {
            dispatch(setLanguage('pt'));
        } else if (navigator.language.includes('es')) {
            dispatch(setLanguage('es'));
        } else {
            dispatch(setLanguage('en'));
        }
    }, [dispatch])

    return (
        <Grommet full>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/promo/:id" element={<HomePage />} />
                    <Route path='/promoWhats' element={<HomePage buyButtonId="buy_btn_1N8lcCH5eAYi3bVUFM629NWI" />} />
                    <Route path="/create/:sessionId" element={<AccountPage />} />
                    <Route path="/configure/:sessionId" element={<AccountPage />} />
                    <Route path='/links/:sessionId' element={<ProfileFromSessionId />} />
                    <Route path='/iam/:username' element={<ProfileFromUsername />} />
                    <Route path='/events' element={<EventsPage />} />


                    <Route path='/valor/:username' element={<ProfileFromUsername />} />
                    <Route path='/event/:eventName' element={<EventsQrCodes />} />
                </Routes>
            </BrowserRouter>
        </Grommet>
    )
}

export default App;
