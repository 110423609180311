import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const socialApi = createApi({
    reducerPath: "social",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.NODE_ENV === "production" ? process.env.CLIENT : "http://localhost:3000"
    }),
    endpoints(builder) {
        return {
            updateConnections: builder.mutation({
                query: ({sessionId, connections}) => {
                    return {
                        url: `/api/social/update/${sessionId}`,
                        method: 'POST',
                        body: connections
                    }
                },
                invalidatesTags: ["social"]
            }),
        }
    }
});

export const {
    useUpdateConnectionsMutation
} = socialApi;
export { socialApi };