export const pt = {
    menu: {
        edit: "Editar",
        profile: "Meu Perfil",
        card: "Cartão",
        home: "Início",
        howItWorks: "Como funciona",
        price: "Preço",
        corporate: "Para empresas",
        events: "Para eventos",
        create: "Crie seu cartão de visitas virtual",
        terms: "Termos de uso",
        privacy: "Política de privacidade",
        contact: "Contato",
        start: "Começar",
        meeting: "Agendar reunião",
        vcf: "Salvar contato"
    },
    home: {
        banner: {
            title: "Simplifique seu networking",
            paragraph: "Diga adeus ao papel, plástico ou metal. Seu novo cartão de visita mora na sua Carteira Google ou Apple.",
            label: "Teste grátis por 7 dias",
            text: "Depois"
        },
        color: {
            subtitle: "Personalize à sua maneira",
            title: "Escolha sua cor favorita",
            paragraph: "Com várias opções de cores, você pode personalizar o seu cartão para refletir a sua personalidade e estilo. Torne o seu cartão de visita único e memorável.",
            cta: "Pronto para começar?"
        },
        download: {
            subtitle: "Moderno, prático, sustentável",
            title: "Pronto para levar seu networking a outro patamar?",
            paragraph: "Comece a usar o Nice2Meet.io hoje mesmo — com zero espera.",
            cta: "Pronto para começar?"
        },
        features: {
            subtitle: "Conecte-se em qualquer lugar",
            title: "Nunca mais se preocupe em ficar sem cartões de visita",
            paragraph: "Com o nosso cartão de visita virtual, você está sempre pronto para se conectar e expandir a sua rede.",
            carousel: [
                ["Sempre à mão", "Esqueça (sem culpa) os cartões de visita físicos. Com Nice2Meet.io, seus dados de contato estão sempre com você e disponíveis a um toque na tela do seu smartphone."],
                ["Totalmente personalizável", "Altere a cor, dados e mídias sociais. Com a nossa ferramenta, você tem total liberdade para personalizar seu cartão de visita virtual, tornando-o único e representativo da sua marca pessoal."],
                ["Qualquer smartphone", "Salvo direto na sua Carteira Google ou Apple. Não importa o dispositivo que você ou seus contatos usem, nossos cartões de visita virtuais são compatíveis e práticos para todos."],
                ["Moderno e sustentável", "Adeus aos cartões em papel, metal ou plástico. Com Nice2Meet.io, você demonstra sua consciência ambiental e se mantém na vanguarda das tendências de networking digital."],
                ["Zero espera", "Compre, baixe e comece a usar hoje mesmo. A eficiência é a chave do nosso serviço. Em poucos minutos, você pode ter seu cartão de visita virtual pronto para ser compartilhado e impressionar seus contatos."],
            ]
        },
        how: {
            subtitle: "Como funciona",
            title: "Muito simples de usar",
            paragraph: "É mais fácil e rápido do que você imagina. Siga 3 passos simples:",
            subscribe: "Faça um teste gratuito",
            configure: "Adicione seus contatos",
            download: "Baixe para a sua Wallet"
        },
        clients: {
            title: "Algumas equipes que já usam nosso cartão de visitas virtual"
        },
        prices: {
            top: {
                title: "Planos Simples e Acessíveis",
                subtitle: "Cartão completo",
                paragraph: "O Nice2Meet.io tem planos acessíveis que se adaptam às suas preferências",
                monthly: "Mensal",
                annually: "Anual"
            },
            monthly: [
                {
                    name: "INDIVIDUAL",
                    currency: "R$",
                    price: "1,99",
                    recurrence: "Por mês",
                    features: [
                        "1 cartão",
                        "Alterações ilimitadas",
                        "Link para o perfil",
                        "-",
                        "-"
                    ],
                    url: "https://buy.stripe.com/eVaaFQ4EX3jx1TG149",
                    cta: "Começar"
                },
                {
                    name: "INDIVIDUAL+",
                    currency: "R$",
                    price: "2,99",
                    recurrence: "Por mês",
                    features: [
                        "1 cartão",
                        "Alterações ilimitadas",
                        "Link para o perfil",
                        "Analytics",
                        "-"
                    ],
                    url: "https://buy.stripe.com/14k3do9Zh6vJ55S4gn",
                    cta: "Começar"
                },
                {
                    name: "EMPRESA",
                    currency: "R$",
                    price: "3,99",
                    recurrence: "Por usuário por mês",
                    features: [
                        "1 cartão",
                        "Alterações ilimitadas",
                        "Link para o perfil",
                        "Analytics",
                        "Identidade de marca"
                    ],
                    url: "https://buy.stripe.com/dR63dob3ldYbgOA28i",
                    cta: "Começar"
                }
            ],
            annually: [
                {
                    name: "INDIVIDUAL",
                    currency: "R$",
                    price: "14,99",
                    recurrence: "Por ano",
                    features: [
                        "1 cartão",
                        "Alterações ilimitadas",
                        "Link para o perfil",
                        "-",
                        "Experimente por 7 dias"
                    ],
                    url: "https://buy.stripe.com/dR6eW61sL9HVfKw6ou",
                    cta: "Avaliar"
                },
                {
                    name: "INDIVIDUAL+",
                    currency: "R$",
                    price: "21,99",
                    recurrence: "Por ano",
                    features: [
                        "1 cartão",
                        "Alterações ilimitadas",
                        "Link para o perfil",
                        "Analytics",
                        "-"
                    ],
                    url: "https://buy.stripe.com/5kA7tEdbt5rF41OeV2",
                    cta: "Começar"
                },
                {
                    name: "EMPRESA",
                    currency: "R$",
                    price: "31,99",
                    recurrence: "Por usuário por ano",
                    features: [
                        "1 cartão",
                        "Alterações ilimitadas",
                        "Link para o perfil",
                        "Analytics",
                        "Identidade de marca"
                    ],
                    url: "https://buy.stripe.com/9AQg0afjB8DR8i45kt",
                    cta: "Começar"
                }
            ]
        }

    },
    configure: {
        title: "Vamos configurar",
        paragraph: "Personalize seu cartão de visita virtual de forma rápida e fácil.",
        color: "Escolher aparência",
        colors: ["Colorido", "Claro", "Escuro"],
        data: "dados",
        fields: [
            {
                "name": "fullname",
                "label": "Nome completo",
                "placeholder": "Seu nome",
                "errorMessage": "É necessário digitar o nome completo.",
                "required": true
            },
            {
                "name": "jobTitle",
                "label": "Cargo",
                "placeholder": "Sua posição",
                "errorMessage": "É necessário digitar o cargo.",
                "required": true
            },
            {
                "name": "companyName",
                "label": "Nome da Empresa",
                "placeholder": "Sua empresa",
                "errorMessage": "É necessário digitar o nome da empresa.",
                "required": true
            },
            {
                "name": "email",
                "label": "Endereço de e-mail",
                "placeholder": "nome@exemplo.com.br",
                "errorMessage": "Por favor digite um email válido.",
                "required": true
            },
            {
                "name": "phoneNumber",
                "label": "Número de telefone",
                "placeholder": "(11) 91234-5678",
                "errorMessage": "Por favor digite um telefone válido.",
                "required": false
            },
            {
                "name": "phoneNumber2",
                "label": "Número de telefone 2",
                "placeholder": "(11) 91234-5678",
                "errorMessage": "Por favor digite um telefone válido.",
                "required": false
            },
            {
                "name": "website",
                "label": "Endereço do site",
                "placeholder": "www.exemplo.com.br",
                "errorMessage": "Por favor digite um endereço de site válido.",
                "required": false
            },
            {
                "name": "linkedin",
                "label": "URL do perfil no LinkedIn",
                "placeholder": "https://www.linkedin.com/in/usuario",
                "errorMessage": "Por favor digite um perfil de Linkedin válido.",
                "required": false
            },
            {
                "name": "twitter",
                "label": "URL do perfil no Twitter",
                "placeholder": "https://www.twitter.com/usuario",
                "errorMessage": "Por favor digite um perfil de Twitter válido.",
                "required": false
            },
            {
                "name": "facebook",
                "label": "URL do perfil no Facebook",
                "placeholder": "https://www.facebook.com/usuario",
                "errorMessage": "Por favor digite um perfil de Facebook válido.",
                "required": false
            },
            {
                "name": "instagram",
                "label": "URL do perfil no Instagram",
                "placeholder": "https://www.instagram.com/usuario",
                "errorMessage": "Por favor digite um perfil de Instagram válido.",
                "required": false
            },
            {
                "name": "calendly",
                "label": "URL da página do Calendly",
                "placeholder": "https://calendly.com/usuario",
                "errorMessage": "Por favor digite uma página do Calendly válida.",
                "required": false
            }
        ],
        verify: "Confira os campos destacados",
        save: "Existem alterações não salvas",
        generate: "Salvar e gerar cartões",
        see: "Veja e compartilhe seu cartão em",
        questions: "Tem alguma dúvida?",
        support: "Entre em contato com o nosso suporte em support@nice2meet.io."
    },
    loading: "Carregando...",
    error: "Algo deu errado",
    alsoSeen: {
        title: "Seus novos contatos organizados"
    },
    tips: {
        title: "Dicas para um bom networking",
        tips: [
            {
                title: "Seja genuíno e autêntico",
                paragraphs: [
                    "Ao fazer networking, seja você mesmo e conecte-se genuinamente com os outros. Mostre interesse pelo trabalho deles, ouça ativamente e seja autêntico em suas interações.",
                    "Construir relacionamentos genuínos causará uma impressão duradoura e ajudará a promover conexões significativas."
                ]
            },
            {
                title: "Mantenha o relacionamento",
                paragraphs: [
                    "Depois de conhecer alguém, certifique-se de acompanhar e manter-se conectado. Envie uma mensagem personalizada, conecte-se em plataformas de rede profissional como o LinkedIn e encontre maneiras de continuar a conversa.",
                    "Manter contato regular mostra seu interesse e compromisso com o relacionamento."
                ]
            },
            {
                title: "Dê e receba",
                paragraphs: [
                    "Networking é uma via de mão dupla. Ofereça ajuda, suporte e recursos a outras pessoas sem esperar retornos imediatos.",
                    "Construir uma rede baseada em troca mútua cria um ambiente positivo e abre portas para colaboração e oportunidades no futuro."
                ]
            },
        ]
    },
    events: {
        banner: {
            title: "Ofereça uma experiência de networking única para os seus participantes",
            cta: "Começar",
            know: "Saiba como"
        },
        features: [
            {
                title: "Engajamento Intensificado",
                subtitle: "Networking",
                paragraphs: [
                    "Os cartões de visita digitais Nice2Meet.io oferecem uma maneira rápida e eficiente de trocar informações de contato.",
                    "Este processo sem esforço pode estimular a interação entre os participantes, proporcionando um networking mais rico e conectado."
                ]
            },
            {
                title: "Posicionamento Moderno",
                subtitle: "Inovação",
                paragraphs: [
                    "Ao adotar os cartões de visita digitais Nice2Meet.io, seu evento demonstra estar na vanguarda da inovação e sustentabilidade.",
                    "É uma maneira eficaz de posicionar seu evento como um líder em tendências digitais, criando um impacto positivo na percepção de seu público."
                ]
            },
            {
                title: "Memorabilidade Aprimorada",
                subtitle: "Brinde",
                paragraphs: [
                    "Com Nice2Meet.io, os participantes do seu evento levam consigo um lembrete tangível da experiência.",
                    "Cada vez que usam o cartão de visita digital, eles são lembrados do seu evento, o que pode ajudar a manter a experiência viva em suas mentes e facilitar futuras conexões."
                ]
            },
        ],
        how: {
            title: "É muito simples oferecer os cartões Nice2Meet.io no seu evento",
            subtitle: "Como funciona",
            paragraphs: [
                "Se o seu evento reunir mais de 100 pessoas, a oferta dos nossos cartões digitais será totalmente gratuita para você. Legal, né?",
                "E mesmo se o seu evento for um pouco mais intimista, com menos de 100 participantes, não se preocupe. Haverá apenas uma pequena taxa única de configuração.",
                "O melhor de tudo é que seus convidados poderão usufruir dos cartões de visita gratuitamente durante o evento e também nos 7 dias seguintes. Depois disso, eles podem decidir se querem continuar conosco ou não. Então, todos saem ganhando!"
            ],
            cta: "Começar a configurar",
            features: {
                a: "Avaliação gratuita",
                b: "Dados seguros",
                c: "Configuração rápida",
                d: "QR Code Individual"
            }
        },
        cta: {
            title: "A gente te ajuda com isso, sem estresse!",
            subtitle: "Buscando uma solução rápida?",
            paragraph: "Criamos um processo passo a passo especialmente para você. Obtenha QR Codes personalizados e cartões de visita para seus participantes em minutos!",
            cta: "USE NOSSO PASSO A PASSO"
        }
    }
}

export const en = {
    menu: {
        edit: "Edit",
        profile: "My Profile",
        card: "Card",
        home: "Home",
        howItWorks: "How it works",
        price: "Price",
        corporate: "For businesses",
        events: "For events",
        create: "Create your virtual business card",
        terms: "Terms of use",
        privacy: "Privacy policy",
        contact: "Contact",
        start: "Start",
        meeting: "Schedule a meeting",
        vcf: "Add to Contacts"
    },
    home: {
        banner: {
            title: "Simplify your networking",
            paragraph: "Say goodbye to paper, plastic, metal or bamboo. Your new business card lives in your Google or Apple Wallet.",
            label: "Free trial for 7 days",
            text: "Then",
        },
        color: {
            subtitle: "Customize it your way",
            title: "Choose your favorite color",
            paragraph: "With various color options, you can personalize your card to reflect your personality and style. Make your business card unique and memorable.",
            cta: "Ready to start?"
        },
        download: {
            subtitle: "Modern, practical, sustainable",
            title: "Ready to take your networking to another level?",
            paragraph: "Start using Nice2Meet.io today — with zero wait.",
            cta: "Ready to start?"
        },
        features: {
            subtitle: "Connect anywhere",
            title: "Never worry about running out of business cards again",
            paragraph: "With our virtual business card, you're always ready to connect and expand your network.",
            carousel: [
                ["Always on hand", "Forget (without guilt) physical business cards. With Nice2Meet.io, your contact information is always with you and available at a touch on your smartphone screen."],
                ["Fully customizable", "Change the color, data, and social media. With our tool, you have total freedom to customize your virtual business card, making it unique and representative of your personal brand."],
                ["Any smartphone", "Saved directly to your Google or Apple Wallet. No matter what device you or your contacts use, our virtual business cards are compatible and practical for everyone."],
                ["Modern and sustainable", "Goodbye to paper, metal or plastic cards. With Nice2Meet.io, you demonstrate your environmental awareness and stay at the forefront of digital networking trends."],
                ["Zero wait", "Buy, download and start using today. Efficiency is key to our service. In a few minutes, you can have your virtual business card ready to be shared and impress your contacts."],
            ]
        },
        how: {
            subtitle: "How it works",
            title: "Very simple to use",
            paragraph: "It's easier and faster than you think. Follow 3 simple steps:",
            subscribe: "Subscribe",
            configure: "Configure",
            download: "Download"
        },
        clients: {
            title: "Some teams that already use our virtual business card"
        },
        prices: {
            top: {
                title: "Simple and Affordable Plans",
                subtitle: "Complete card",
                paragraph: "Nice2Meet.io offers affordable plans that adapt to your preferences",
                monthly: "Monthly",
                annually: "Annual"
            },
            monthly: [
                {
                    name: "INDIVIDUAL",
                    currency: "$",
                    price: "1.99",
                    recurrence: "Per month",
                    features: [
                        "1 card",
                        "Unlimited changes",
                        "Link to profile",
                        "-",
                        "-"
                    ],
                    url: "https://buy.stripe.com/eVaaFQ4EX3jx1TG149",
                    cta: "Start"
                },
                {
                    name: "INDIVIDUAL+",
                    currency: "$",
                    price: "2.99",
                    recurrence: "Per month",
                    features: [
                        "1 card",
                        "Unlimited changes",
                        "Link to profile",
                        "Analytics",
                        "-"
                    ],
                    url: "https://buy.stripe.com/14k3do9Zh6vJ55S4gn",
                    cta: "Start"
                },
                {
                    name: "BUSINESS",
                    currency: "$",
                    price: "3.99",
                    recurrence: "Per user per month",
                    features: [
                        "1 card",
                        "Unlimited changes",
                        "Link to profile",
                        "Analytics",
                        "Brand identity"
                    ],
                    url: "https://buy.stripe.com/dR63dob3ldYbgOA28i",
                    cta: "Start"
                }
            ],
            annually: [
                {
                    name: "INDIVIDUAL",
                    currency: "$",
                    price: "14.99",
                    recurrence: "Per year",
                    features: [
                        "1 card",
                        "Unlimited changes",
                        "Link to profile",
                        "-",
                        "-"
                    ],
                    url: "https://buy.stripe.com/dR6eW61sL9HVfKw6ou",
                    cta: "Start"
                },
                {
                    name: "INDIVIDUAL+",
                    currency: "$",
                    price: "21.99",
                    recurrence: "Per year",
                    features: [
                        "1 card",
                        "Unlimited changes",
                        "Link to profile",
                        "Analytics",
                        "-"
                    ],
                    url: "https://buy.stripe.com/5kA7tEdbt5rF41OeV2",
                    cta: "Start"
                },
                {
                    name: "BUSINESS",
                    currency: "$",
                    price: "31.99",
                    recurrence: "Per user per year",
                    features: [
                        "1 card",
                        "Unlimited changes",
                        "Link to profile",
                        "Analytics",
                        "Brand identity"
                    ],
                    url: "https://buy.stripe.com/9AQg0afjB8DR8i45kt",
                    cta: "Start"
                }
            ]
        }
    },
    configure: {
        title: "Let's configure",
        paragraph: "Personalize your virtual business card quickly and easily.",
        color: "Choose appearance",
        colors: ["Colorful", "Light", "Dark"],
        data: "data",
        fields: [
            {
                "name": "fullname",
                "label": "Full name",
                "placeholder": "Gabriel Souza",
                "errorMessage": "Full name is required.",
                "required": true
            },
            {
                "name": "jobTitle",
                "label": "Job Title",
                "placeholder": "Product Manager",
                "errorMessage": "Job title is required.",
                "required": true
            },
            {
                "name": "companyName",
                "label": "Company Name",
                "placeholder": "Acme Inc.",
                "errorMessage": "Company name is required.",
                "required": true
            },
            {
                "name": "email",
                "label": "Email Address",
                "placeholder": "gabriel@example.com",
                "errorMessage": "Please enter a valid email.",
                "required": true
            },
            {
                "name": "phoneNumber",
                "label": "Phone Number",
                "placeholder": "(11) 91234-5678",
                "errorMessage": "Please enter a valid phone number.",
                "required": false
            },
            {
                "name": "phoneNumber2",
                "label": "Phone Number 2",
                "placeholder": "(11) 91234-5678",
                "errorMessage": "Please enter a valid phone number.",
                "required": false
            },
            {
                "name": "website",
                "label": "Website Address",
                "placeholder": "www.example.com",
                "errorMessage": "Please enter a valid website address.",
                "required": false
            },
            {
                "name": "linkedin",
                "label": "LinkedIn Profile URL",
                "placeholder": "https://www.linkedin.com/in/username",
                "errorMessage": "Please enter a valid LinkedIn profile.",
                "required": false
            },
            {
                "name": "twitter",
                "label": "Twitter Profile URL",
                "placeholder": "https://www.twitter.com/username",
                "errorMessage": "Please enter a valid Twitter profile.",
                "required": false
            },
            {
                "name": "facebook",
                "label": "Facebook Profile URL",
                "placeholder": "https://www.facebook.com/username",
                "errorMessage": "Please enter a valid Facebook profile.",
                "required": false
            },
            {
                "name": "instagram",
                "label": "Instagram Profile URL",
                "placeholder": "https://www.instagram.com/username",
                "errorMessage": "Please enter a valid Instagram profile.",
                "required": false
            },
            {
                "name": "calendly",
                "label": "Calendly Page URL",
                "placeholder": "https://calendly.com/username",
                "errorMessage": "Please enter a valid Calendly page.",
                "required": false
            }
        ],
        verify: "Check the highlighted fields above",
        save: "There are unsaved changes",
        generate: "Save and generate cards",
        see: "View and share your card on",
        questions: "Any questions?",
        support: "Contact our support at support@nice2meet.io."
    },
    loading: "Loading...",
    error: "Something went wrong",
    alsoSeen: {
        title: "Who you've met..."
    },
    tips: {
        title: "Tips for Good Networking",
        tips: [
            {
                title: "Be Genuine and Authentic",
                paragraphs: [
                    "When networking, be yourself and genuinely connect with others. Show interest in their work, actively listen, and be authentic in your interactions.",
                    "Building genuine relationships will leave a lasting impression and help promote meaningful connections."
                ]
            },
            {
                title: "Maintain the Relationship",
                paragraphs: [
                    "After meeting someone, make sure to follow up and stay connected. Send a personalized message, connect on professional networking platforms like LinkedIn, and find ways to continue the conversation.",
                    "Keeping regular contact shows your interest and commitment to the relationship."
                ]
            },
            {
                title: "Give and Take",
                paragraphs: [
                    "Networking is a two-way street. Offer help, support, and resources to others without expecting immediate returns.",
                    "Building a network based on mutual exchange creates a positive environment and opens doors to collaboration and opportunities in the future."
                ]
            },
        ]
    },
    events: {
        banner: {
            title: "Offer a unique networking experience for your participants",
            cta: "Get Started",
            know: "Learn How"
        },
        features: [
            {
                title: "Intensified Engagement",
                subtitle: "Networking",
                paragraphs: [
                    "Nice2Meet.io digital business cards offer a quick and efficient way to exchange contact information.",
                    "This effortless process can stimulate interaction among participants, providing a richer and more connected networking experience."
                ]
            },
            {
                title: "Modern Positioning",
                subtitle: "Innovation",
                paragraphs: [
                    "By adopting Nice2Meet.io digital business cards, your event demonstrates being at the forefront of innovation and sustainability.",
                    "It is an effective way to position your event as a leader in digital trends, creating a positive impact on your audience's perception."
                ]
            },
            {
                title: "Enhanced Memorability",
                subtitle: "Keepsake",
                paragraphs: [
                    "With Nice2Meet.io, participants of your event take with them a tangible reminder of the experience.",
                    "Every time they use the digital business card, they are reminded of your event, which can help keep the experience alive in their minds and facilitate future connections."
                ]
            },
        ],
        how: {
            title: "It's very simple to offer Nice2Meet.io cards at your event",
            subtitle: "How it works",
            paragraphs: [
                "If your event gathers more than 100 people, offering our digital cards will be completely free for you. Cool, right?",
                "And even if your event is a bit more intimate, with less than 100 participants, don't worry. There will only be a small one-time setup fee.",
                "The best part is that your guests will be able to enjoy the business cards for free during the event and for the next 7 days. After that, they can decide whether they want to continue with us or not. So, everyone wins!"
            ],
            cta: "Start configuring",
            features: {
                a: "Free trial",
                b: "Secure data",
                c: "Quick setup",
                d: "Individual QR Code"
            }
        },
        cta: {
            title: "We're here to help you with that, stress-free!",
            subtitle: "Looking for a quick solution?",
            paragraph: "We've created a step-by-step process just for you. Get personalized QR Codes and business cards for your participants in minutes!",
            cta: "USE OUR STEP-BY-STEP GUIDE"
        },
    }
};

export const es = {
    menu: {
        edit: "Editar",
        profile: "Mi Perfil",
        card: "Tarjeta",
        home: "Inicio",
        howItWorks: "Cómo funciona",
        price: "Precio",
        corporate: "Para empresas",
        events: "Para eventos",
        create: "Crea tu tarjeta de visita virtual",
        terms: "Términos de uso",
        privacy: "Política de privacidad",
        contact: "Contacto",
        start: "Empezar",
        meeting: "Agendar reunión",
        vcf: "Guardar contactos"
    },
    home: {
        banner: {
            title: "Simplifica tu networking",
            paragraph: "Di adiós al papel, plástico o metal. Tu nueva tarjeta de visita reside en tu Google Wallet o Apple Wallet.",
            label: "Prueba gratuita por 7 días",
            text: "Luego"
        },
        color: {
            subtitle: "Personalízalo a tu manera",
            title: "Elige tu color favorito",
            paragraph: "Con varias opciones de colores, puedes personalizar tu tarjeta para reflejar tu personalidad y estilo. Haz tu tarjeta de visita única y memorable.",
            cta: "¿Listo para empezar?"
        },
        download: {
            subtitle: "Moderno, práctico, sostenible",
            title: "¿Listo para llevar tu networking a otro nivel?",
            paragraph: "Comienza a usar Nice2Meet.io hoy mismo, sin esperas.",
            cta: "¿Listo para empezar?"
        },
        features: {
            subtitle: "Conéctate en cualquier lugar",
            title: "Nunca más te preocupes por quedarte sin tarjetas de visita",
            paragraph: "Con nuestra tarjeta de visita virtual, siempre estás listo para conectarte y expandir tu red.",
            carousel: [
                ["Siempre a mano", "Olvida (sin culpa) las tarjetas de visita físicas. Con Nice2Meet.io, tus datos de contacto siempre están contigo y disponibles con un toque en la pantalla de tu smartphone."],
                ["Totalmente personalizable", "Cambia el color, los datos y las redes sociales. Con nuestra herramienta, tienes total libertad para personalizar tu tarjeta de visita virtual, haciéndola única y representativa de tu marca personal."],
                ["Cualquier smartphone", "Guardado directamente en tu Google Wallet o Apple Wallet. No importa el dispositivo que tú o tus contactos utilicen, nuestras tarjetas de visita virtuales son compatibles y prácticas para todos."],
                ["Moderno y sostenible", "Adiós a las tarjetas de papel, metal o plástico. Con Nice2Meet.io, demuestras tu conciencia ambiental y te mantienes a la vanguardia de las tendencias de networking digital."],
                ["Cero espera", "Compra, descarga y comienza a usar hoy mismo. La eficiencia es la clave de nuestro servicio. En pocos minutos, puedes tener tu tarjeta de visita virtual lista para ser compartida e impresionar a tus contactos."],
            ]
        },
        how: {
            subtitle: "Cómo funciona",
            title: "Muy fácil de usar",
            paragraph: "Es más fácil y rápido de lo que imaginas. Sigue 3 sencillos pasos:",
            subscribe: "Suscríbete",
            configure: "Configura",
            download: "Descarga"
        },
        clients:
        {
            title: "Algunos equipos que ya utilizan nuestra tarjeta de visita virtual"
        },
        prices: {
            top: {
                title: "Planes Simples y Asequibles",
                subtitle: "Tarjeta completa",
                paragraph: "Nice2Meet.io ofrece planes asequibles que se adaptan a tus preferencias",
                monthly: "Mensual",
                annually: "Anual"
            },
            monthly: [
                {
                    name: "INDIVIDUAL",
                    currency: "$",
                    price: "1,99",
                    recurrence: "Por mes",
                    features: [
                        "1 tarjeta",
                        "Modificaciones ilimitadas",
                        "Enlace al perfil",
                        "-",
                        "-"
                    ],
                    url: "https://buy.stripe.com/eVaaFQ4EX3jx1TG149",
                    cta: "Empezar"
                },
                {
                    name: "INDIVIDUAL+",
                    currency: "$",
                    price: "2,99",
                    recurrence: "Por mes",
                    features: [
                        "1 tarjeta",
                        "Modificaciones ilimitadas",
                        "Enlace al perfil",
                        "Analíticas",
                        "-"
                    ],
                    url: "https://buy.stripe.com/14k3do9Zh6vJ55S4gn",
                    cta: "Empezar"
                },
                {
                    name: "EMPRESA",
                    currency: "$",
                    price: "3,99",
                    recurrence: "Por usuario por mes",
                    features: [
                        "1 tarjeta",
                        "Modificaciones ilimitadas",
                        "Enlace al perfil",
                        "Analíticas",
                        "Identidad de marca"
                    ],
                    url: "https://buy.stripe.com/dR63dob3ldYbgOA28i",
                    cta: "Empezar"
                }
            ],
            annually: [
                {
                    name: "INDIVIDUAL",
                    currency: "$",
                    price: "14,99",
                    recurrence: "Por año",
                    features: [
                        "1 tarjeta",
                        "Modificaciones ilimitadas",
                        "Enlace al perfil",
                        "-",
                        "-"
                    ],
                    url: "https://buy.stripe.com/dR6eW61sL9HVfKw6ou",
                    cta: "Empezar"
                },
                {
                    name: "INDIVIDUAL+",
                    currency: "$",
                    price: "21,99",
                    recurrence: "Por año",
                    features: [
                        "1 tarjeta",
                        "Modificaciones ilimitadas",
                        "Enlace al perfil",
                        "Analíticas",
                        "-"
                    ],
                    url: "https://buy.stripe.com/5kA7tEdbt5rF41OeV2",
                    cta: "Empezar"
                },
                {
                    name: "EMPRESA",
                    currency: "$",
                    price: "31,99",
                    recurrence: "Por usuario por año",
                    features: [
                        "1 tarjeta",
                        "Modificaciones ilimitadas",
                        "Enlace al perfil",
                        "Analíticas",
                        "Identidad de marca"
                    ],
                    url: "https://buy.stripe.com/9AQg0afjB8DR8i45kt",
                    cta: "Empezar"
                }
            ]
        }
    },
    configure: {
        title: "Vamos a configurar",
        paragraph: "Personaliza tu tarjeta de visita virtual de forma rápida y sencilla.",
        color: "Elegir apariencia",
        colors: ["Colorido", "Claro", "Oscuro"],
        data: "datos",
        fields: [
            {
                "name": "fullname",
                "label": "Nombre completo",
                "placeholder": "Gabriel Souza",
                "errorMessage": "Es necesario introducir el nombre completo.",
                "required": true
            },
            {
                "name": "jobTitle",
                "label": "Puesto",
                "placeholder": "Desarrollador de software",
                "errorMessage": "Es necesario introducir un puesto.",
                "required": true
            },
            {
                "name": "email",
                "label": "Email",
                "placeholder": "gabriel@example.com",
                "errorMessage": "Es necesario introducir un email válido.",
                "required": true
            },
            {
                "name": "phone",
                "label": "Teléfono",
                "placeholder": "+55 11 99999-9999",
                "errorMessage": "Es necesario introducir un teléfono válido.",
                "required": true
            },
            {
                "name": "website",
                "label": "Sitio web",
                "placeholder": "www.example.com",
                "errorMessage": "Es necesario introducir un sitio web válido.",
                "required": false
            },
            {
                "name": "address",
                "label": "Dirección",
                "placeholder": "Calle de la Amargura, 123",
                "errorMessage": "Es necesario introducir una dirección válida.",
                "required": false
            }
        ],
        socials: "Redes sociales",
        networks: [
            {
                "name": "linkedin",
                "placeholder": "/in/gabrielsouza",
                "errorMessage": "Es necesario introducir un LinkedIn válido."
            },
            {
                "name": "twitter",
                "placeholder": "@gabrielsouza",
                "errorMessage": "Es necesario introducir un Twitter válido."
            },
            {
                "name": "instagram",
                "placeholder": "@gabrielsouza",
                "errorMessage": "Es necesario introducir un Instagram válido."
            },
            {
                "name": "facebook",
                "placeholder": "/gabrielsouza",
                "errorMessage": "Es necesario introducir un Facebook válido."
            },
            {
                "name": "github",
                "placeholder": "@gabrielsouza",
                "errorMessage": "Es necesario introducir un Github válido."
            },
            {
                "name": "youtube",
                "placeholder": "/gabrielsouza",
                "errorMessage": "Es necesario introducir un Youtube válido."
            }
        ],
        cta: "Crear tarjeta",
        success: {
            title: "¡Bien hecho!",
            paragraph: "Tu tarjeta de visita virtual ha sido creada. Ahora puedes descargarla y comenzar a compartirla con el mundo.",
            cta: "Descargar"
        }
    },
    alsoSeen: {
        title: "A quién has conocido..."
    },
    tips: {
        title: "Consejos para un buen networking",
        tips: [
            {
                title: "Sé genuino y auténtico",
                paragraphs: [
                    "Al hacer networking, sé tú mismo y conecta genuinamente con los demás. Muestra interés en su trabajo, escucha activamente y sé auténtico en tus interacciones.",
                    "Construir relaciones genuinas dejará una impresión duradera y ayudará a promover conexiones significativas."
                ]
            },
            {
                title: "Mantén la relación",
                paragraphs: [
                    "Después de conocer a alguien, asegúrate de hacer un seguimiento y mantener la conexión. Envía un mensaje personalizado, conéctate en plataformas de networking profesional como LinkedIn y encuentra formas de continuar la conversación.",
                    "Mantener contacto regular demuestra tu interés y compromiso con la relación."
                ]
            },
            {
                title: "Dar y recibir",
                paragraphs: [
                    "El networking es una calle de doble sentido. Ofrece ayuda, soporte y recursos a los demás sin esperar retornos inmediatos.",
                    "Construir una red basada en intercambio mutuo crea un ambiente positivo y abre puertas a la colaboración y oportunidades en el futuro."
                ]
            },
        ]
    },
    events: {
        banner: {
            title: "Ofrece una experiencia de networking única para tus participantes",
            cta: "Comenzar",
            know: "Conoce cómo"
        },
        features: [
            {
                title: "Participación Intensificada",
                subtitle: "Networking",
                paragraphs: [
                    "Las tarjetas de visita digitales de Nice2Meet.io ofrecen una forma rápida y eficiente de intercambiar información de contacto.",
                    "Este proceso sin esfuerzo puede fomentar la interacción entre los participantes, brindando una experiencia de networking más enriquecedora y conectada."
                ]
            },
            {
                title: "Posicionamiento Moderno",
                subtitle: "Innovación",
                paragraphs: [
                    "Al adoptar las tarjetas de visita digitales de Nice2Meet.io, tu evento demuestra estar a la vanguardia de la innovación y la sostenibilidad.",
                    "Es una forma efectiva de posicionar tu evento como líder en las tendencias digitales, creando un impacto positivo en la percepción de tu público."
                ]
            },
            {
                title: "Mejora de la Memorabilidad",
                subtitle: "Recuerdo",
                paragraphs: [
                    "Con Nice2Meet.io, los participantes de tu evento se llevan consigo un recordatorio tangible de la experiencia.",
                    "Cada vez que utilizan la tarjeta de visita digital, se les recuerda tu evento, lo que puede ayudar a mantener viva la experiencia en sus mentes y facilitar futuras conexiones."
                ]
            },
        ],
        how: {
            title: "Es muy sencillo ofrecer las tarjetas Nice2Meet.io en tu evento",
            subtitle: "Cómo funciona",
            paragraphs: [
                "Si tu evento reúne a más de 100 personas, la oferta de nuestras tarjetas digitales será completamente gratuita para ti. ¡Genial, ¿verdad?",
                "Y aunque tu evento sea un poco más íntimo, con menos de 100 participantes, no te preocupes. Solo habrá una pequeña tarifa de configuración única.",
                "Lo mejor de todo es que tus invitados podrán disfrutar de las tarjetas de visita de forma gratuita durante el evento y durante los siguientes 7 días. Después de eso, podrán decidir si quieren continuar con nosotros o no. ¡Así que todos salen ganando!"
            ],
            cta: "Comenzar a configurar",
            features: {
                a: "Prueba gratuita",
                b: "Datos seguros",
                c: "Configuración rápida",
                d: "Código QR individual"
            },
        },
        cta: {
            title: "¡Estamos aquí para ayudarte, sin estrés!",
            subtitle: "¿Buscas una solución rápida?",
            paragraph: "Hemos creado un proceso paso a paso especialmente para ti. ¡Obtén códigos QR personalizados y tarjetas de visita para tus participantes en minutos!",
            cta: "UTILIZA NUESTRA GUÍA PASO A PASO"
        }
    }
}