import { useSelector } from "react-redux";

const EventsFeature = () => {
    const { copy } = useSelector((state) => state.preference);

    return (
        <section className="feature-section">
            <div className="container">
                <div className="row align-items-center mb-40-none padding-bottom">
                    <div className="col-lg-6 mb-40">
                        <div className="feature-thumb-16">
                            <img src="./assets/images/extra/01.png" alt="extra" className="w-100" />
                            <div className="anime-1"><img src="./assets/images/extra/01_sub.png" alt="extra" /></div>
                            <div className="anime-2"><img src="./assets/images/extra/02_sub.png" alt="extra" /></div>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-40">
                        <div className="section-header left-style mb-0">
                            <h5 className="cate">{copy.events.features[0].subtitle}</h5>
                            <h2 className="title">{copy.events.features[0].title}</h2>
                            {copy.events.features[0].paragraphs.map((paragraph, i) => <p key={i}>{paragraph}</p>)}
                        </div>
                    </div>
                </div>
                <div className="row align-items-center flex-row-reverse mb-40-none padding-bottom">
                    <div className="col-lg-6 mb-40">
                        <div className="feature-thumb-16">
                            <img src="./assets/images/extra/02.png" alt="extra" className="w-100" />
                            <div className="anime-1 anime-3"><img src="./assets/images/extra/03_sub.png" alt="extra" /></div>
                            <div className="anime-2"><img src="./assets/images/extra/04_sub.png" alt="extra" /></div>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-40">
                        <div className="section-header left-style mb-0">
                            <h5 className="cate">{copy.events.features[1].subtitle}</h5>
                            <h2 className="title">{copy.events.features[1].title}</h2>
                            {copy.events.features[1].paragraphs.map((paragraph, i) => <p key={i}>{paragraph}</p>)}
                        </div>
                    </div>
                </div>
                <div className="row align-items-center mb-40-none padding-bottom">
                    <div className="col-lg-6 mb-40">
                        <div className="feature-thumb-16">
                            <img src="./assets/images/extra/03.png" alt="extra" className="w-100" />
                        </div>
                    </div>
                    <div className="col-lg-6 mb-40">
                        <div className="section-header left-style mb-0">
                            <h5 className="cate">{copy.events.features[2].subtitle}</h5>
                            <h2 className="title">{copy.events.features[2].title}</h2>
                            {copy.events.features[2].paragraphs.map((paragraph, i) => <p key={i}>{paragraph}</p>)}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EventsFeature;