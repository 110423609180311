import { useEffect } from "react";
import { Box, Layer, Text } from "grommet";
import { useLogErrorMutation } from "../store"
import { useSelector } from "react-redux";
import { registerClick } from "./registeredClick";

const NiceError = ({ component, error }) => {
    const [logError] = useLogErrorMutation();
    const { copy } = useSelector((state) => state.preference);

    useEffect(() => {
        logError(error, component);
        console.log(error, component);
        registerClick('error', 'error_' + component, null)
    }, [error, component, logError])

    return (
        <Layer full background="background-back" animation="none">
            <Box style={{ position: "fixed", top: "calc(50% - 100px)", left: "calc(50% - 100px)", width: "200px", height: "200px" }}
                align="center"
                justify="center"
                gap="20px"
            >
                <Text color="text-weak" style={{ fontWeight: "600", marginLeft: "20px" }}>
                    {copy.error}
                </Text>
            </Box>
        </Layer>
    )
}

export default NiceError;