import OwlCarousel from 'react-owl-carousel2';

import background from "../img/new/backgrounds/color.png";
import color01 from "../img/new/screenshots/color01.png";
import color02 from "../img/new/screenshots/color02.png";
import { useSelector } from "react-redux";
import { registerClick } from "./registeredClick";

const ColorSection = () => {
    const { copy } = useSelector((state) => state.preference);

    return (
        <section className="apps-screenshot-section-20 padding-top padding-bottom pb-max-lg-0 overflow-hidden position-relative">
            <div className="app-screenshot-20 d-none d-lg-flex">
                <img src={background} alt="extra-2/screenshot" />
            </div>
            <div className="container">
                <div className="row justify-content-lg-between justify-content-center align-items-center flex-wrap-reverse">
                    <div className="col-md-8 col-lg-7 col-xl-8">
                        <OwlCarousel
                            options={{
                                items: 1,
                                nav: false,
                                rewind: true,
                                autoplay: true,
                                dots: false
                            }}
                        >
                            <div className="thumbs">
                                <img src={color01} alt="extra-2/screenshot" />
                            </div>
                            <div className="thumbs">
                                <img src={color02} alt="extra-2/screenshot" />
                            </div>
                        </OwlCarousel>
                    </div>
                    <div className="col-lg-5 col-xl-4">
                        <div className="mb-3 mb-lg-0">
                            <div className="section-header left-style mb-olpo">
                                <h5 className="cate">{copy.home.color.subtitle}</h5>
                                <h2 className="title">{copy.home.color.title}</h2>
                                <p>
                                    {copy.home.color.paragraph}
                                </p>
                                {/* <div className="feat-nav mt-0">
                                    <a href="#go" className="feat-prev"><i className="flaticon-left"></i></a>
                                    <a href="#go" className="feat-next active"><i className="flaticon-right"></i></a>
                                </div> */}
                            </div>
                            <h6 className="title mb-4">{copy.home.color.cta}</h6>
                            <ul className="download-options justify-content-start download-option-20"
                                onClick={() => window.open(copy.home.prices.annually[0].url, "_self")}
                                style={{ cursor: "pointer" }}
                            >
                                <li>
                                    <a href={copy.home.prices.annually[0].url} className="active" onClick={() => registerClick('checkout', 'color_cta', 'iPhone')}><i className="fab fa-apple"></i></a>
                                    <span>iPhone</span>
                                </li>
                                <li>
                                    <a href={copy.home.prices.annually[0].url} onClick={() => registerClick('checkout', 'color_cta', 'Android')}><i className="fab fa-android"></i></a>
                                    <span>Android</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ColorSection;