import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { profileApi, useConfigureProfileMutation, useFetchSeenQuery, useFetchProfileQuery, useFetchUserPageQuery } from './apis/profileApi';
import { adminApi, useUpdateMailingMutation } from './apis/adminApi';
import { eventsApi, useFetchEventQuery } from './apis/eventsApi';
import { androidApi, useFetchPassUrlQuery, useUpdatePassUrlMutation } from './apis/androidApi';
import { logsApi, useLogErrorMutation } from './apis/logsApi';
import { socialApi, useUpdateConnectionsMutation } from './apis/socialApi';
import preferenceSlice, { setLanguage, setSeen } from './slices/preferenceSlice';

const store = configureStore({
    reducer: {
        preference: preferenceSlice,
        [profileApi.reducerPath]: profileApi.reducer,
        [adminApi.reducerPath]: adminApi.reducer,
        [eventsApi.reducerPath]: eventsApi.reducer,
        [androidApi.reducerPath]: androidApi.reducer,
        [logsApi.reducerPath]: logsApi.reducer,
        [socialApi.reducerPath]: socialApi.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
        .concat(profileApi.middleware)
        .concat(adminApi.middleware)
        .concat(eventsApi.middleware)
        .concat(androidApi.middleware)
        .concat(logsApi.middleware)
        .concat(socialApi.middleware)
})

setupListeners(store.dispatch);

export default store;
export { useConfigureProfileMutation, useFetchProfileQuery, useFetchSeenQuery, useFetchUserPageQuery };
export { useUpdateMailingMutation };
export { useFetchEventQuery };
export { useFetchPassUrlQuery, useUpdatePassUrlMutation };
export { useLogErrorMutation };
export { setLanguage, setSeen };
export { useUpdateConnectionsMutation };