import { useSelector } from "react-redux";
import ConfigForm from "./ConfigForm";

const ConfigSection = () => {
    const { copy } = useSelector((state) => state.preference);

    return (
        <section className="contact-section padding-top padding-bottom">
            <div className="container">
                <div className="section-header mw-100 cl-white">
                    <h2 className="title">{copy.configure.title}</h2>
                    <p>{copy.configure.paragraph}</p>
                </div>
                <ConfigForm />
            </div>
        </section>
    )
}

export default ConfigSection;