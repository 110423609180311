import background from "../img/new/backgrounds/banner-1.png";
import slide1 from "../img/new/screenshots/banner-screen-1.png";
import slide2 from "../img/new/screenshots/banner-screen-2.png";
import { Box } from "grommet";
import { useSelector } from "react-redux";
import { useState } from "react";
import DynamicPrice from "./DynamicPrice";

const BannerSection = () => {
    const { copy } = useSelector((state) => state.preference);
    const [banner, setBanner] = useState(true);
    
    return (
        <section className="banner-4 bg_img oh bottom_left" style={{ backgroundImage: "url(" + background + ")" }} id="home">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-7">
                        <div className="banner-content-3">
                            <h1 className="title">{copy.home.banner.title}</h1>
                            <p>
                                {copy.home.banner.paragraph}
                            </p>
                            <Box justify="center" style={{ width: "fit-content" }}>
                                <DynamicPrice/>
                            </Box>
                        </div>
                    </div>
                    <div className="col-xl-5">
                        <div className="banner-nav-container bg_img bg_contain bottom_center d-lg-block d-none" style={{ backgroundImage: "url(" + (banner ? slide1 : slide2) + ")" }} >
                            <div className="ban-nav">
                                <a href="#0" className="ban-prev active" onClick={() => setBanner(!banner)}>
                                    <i className="flaticon-left"></i>
                                </a>
                                <a href="#0" className="ban-next" onClick={() => setBanner(!banner)}>
                                    <i className="flaticon-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-lg-none d-sm-block">
                    <img src={(banner ? slide1 : slide2)} onClick={() => setBanner(!banner)} alt="screenshots" style={{ maxWidth: "100vw", marginLeft: "-15px", paddingTop: "40px" }}/>
                </div>
            </div>
        </section>
    )
}

export default BannerSection;