import logo from "../img/new/logo/logo.svg";
import background from "../img/new/backgrounds/footer.png";
import { Box } from "grommet";
import { useSelector } from "react-redux";

const FooterSection = () => {
    const { copy } = useSelector((state) => state.preference);

    return (
        <Box className="footer-section bg_img" style={{ backgroundImage: "url(" + background + ")" }}>
            <div className="container">
                <div className="footer-top padding-top padding-bottom">
                    <div className="logo">
                        <a href="/">
                            <img src={logo} alt="logo" />
                        </a>
                    </div>
                </div>
                <div className="footer-bottom">
                    <ul className="footer-link light-border-color">
                        <li>
                            <a href="mailto:support@nice2meet.io">{copy.menu.contact}</a>
                        </li>
                        <li>
                            <a href="#go">{copy.menu.terms}</a>
                        </li>
                        <li>
                            <a href="#go">{copy.menu.privacy}</a>
                        </li>
                    </ul>
                </div>
                <div className="copyright light-border">
                    <p>
                        Copyright © 2023. Benti Technologies. All Rights Reserved. 
                    </p>
                </div>
            </div>
        </Box>
    )
}

export default FooterSection;