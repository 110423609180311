import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchProfileQuery, useFetchUserPageQuery } from "../store";
import NiceError from "../components/NiceError";
import ProfileSection from "../components/ProfileSection";
import Loader from "../components/Loader";
import { registerClick } from "../components/registeredClick";

const ProfilePage = ({ data }) => {
    useEffect(() => {
        window.history.replaceState(null, null, "/iam/" + data.username);
    }, [data.username])

    return (
        <ProfileSection data={data} />
    )
}

export const ProfileFromSessionId = () => {
    const { sessionId } = useParams();
    const { data, error, isLoading, isFetching } = useFetchProfileQuery(sessionId);

    const navigate = useNavigate();

    useEffect(() => {
        registerClick('profile', 'seen_QRCode', sessionId);
        registerClick('from', 'from_' + sessionId, null);
    }, [sessionId])

    if (isLoading || isFetching) {
        return (<Loader />)
    } else if (error) {
        return <NiceError component="ProfileFromSessionId" error={error} />
    } else if (data && data.payment === 'complete') {
        return (<ProfilePage data={data} />);
    } else {
        navigate('/');
    }
}

export const ProfileFromUsername = () => {
    const { username } = useParams();
    const { data, error, isLoading, isFetching } = useFetchUserPageQuery(username);

    const navigate = useNavigate();

    useEffect(() => {
        registerClick('profile', 'seen_iam', username);
        registerClick('from', 'from_' + username, null);
    }, [username])

    if (isLoading || isFetching) {
        return (<Loader />)
    } else if (error) {
        return <NiceError component="ProfileFromUsername" error={error} />
    } else if (data && data.payment === 'complete') {
        return (<ProfilePage data={data} />);
    } else {
        navigate('/');
    }
}