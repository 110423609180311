import { Box } from "grommet";
import HeaderSection from "../../components/HeaderSection";
import EventsBanner from "./components/EventsBanner";
import EventsFeature from "./components/EventsFeature";

import EventsCTA from "./components/EventsCTA";
import FooterSection from "../../components/FooterSection";
import EventsFeaturesList from "./components/EventsFeaturesList";

const EventsPage = () => {
    const mailto = "mailto:parcerias@nice2meet.io?subject=Interesse em parceria&body=Nome do evento: %0d%0aData do evento: %0d%0aNúmero de participantes presenciais inscritos: %0d%0aDeseja etiquetas com QRcode de cada participante? %0d%0aDeseja QRCode para palestrantes incluírem em apresentações?%0d%0aMensagem: %0d%0a"

    return (
        <Box>
            <HeaderSection />
            <EventsBanner mailto={mailto} />
            <EventsFeature />
            <EventsFeaturesList mailto={mailto} />
            <EventsCTA mailto={mailto} />
            <FooterSection />
        </Box >
    )
}

export default EventsPage;