import ConfigSection from "../components/ConfigSection";
import FooterSection from "../components/FooterSection";
import HeaderSection from "../components/HeaderSection";
import PageHeader from "../components/PageHeader";
import Tips from "../components/Tips";

const AccountPage = () => {
    return (
        <>
            <HeaderSection />
            <PageHeader />
            <ConfigSection />
            <Tips className="mb-300" />
            <FooterSection/>
        </>
    )
}

export default AccountPage;