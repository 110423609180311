import pt_appleWallet from "../img/pt/AppleWallet.png";
import pt_appleWallet2x from "../img/pt/AppleWallet@2x.png";
import pt_googleWallet from "../img/pt/GoogleWallet.png";
import pt_googleWallet2x from "../img/pt/GoogleWallet@2x.png";
import pt_screenPattern from "../img/pt/screenPattern.png";
import pt_screenPattern2x from "../img/pt/screenPattern@2x.png";
import pt_screenProfile from "../img/pt/screenProfile.png";
import pt_screenProfile2x from "../img/pt/screenProfile@2x.png";
import pt_screenCard from "../img/pt/screenCard.png";
import pt_screenCard2x from "../img/pt/screenCard@2x.png";

import es_appleWallet from "../img/es/AppleWallet.png";
import es_appleWallet2x from "../img/es/AppleWallet@2x.png";
import es_googleWallet from "../img/es/GoogleWallet.png";
import es_googleWallet2x from "../img/es/GoogleWallet@2x.png";
import es_screenPattern from "../img/es/screenPattern.png";
import es_screenPattern2x from "../img/es/screenPattern@2x.png";
import es_screenProfile from "../img/es/screenProfile.png";
import es_screenProfile2x from "../img/es/screenProfile@2x.png";
import es_screenCard from "../img/es/screenCard.png";
import es_screenCard2x from "../img/es/screenCard@2x.png";

import en_appleWallet from "../img/en/AppleWallet.png";
import en_appleWallet2x from "../img/en/AppleWallet@2x.png";
import en_googleWallet from "../img/en/GoogleWallet.png";
import en_googleWallet2x from "../img/en/GoogleWallet@2x.png";
import en_screenPattern from "../img/en/screenPattern.png";
import en_screenPattern2x from "../img/en/screenPattern@2x.png";
import en_screenProfile from "../img/en/screenProfile.png";
import en_screenProfile2x from "../img/en/screenProfile@2x.png";
import en_screenCard from "../img/en/screenCard.png";
import en_screenCard2x from "../img/en/screenCard@2x.png";

import wavingHand from "../img/common/wavingHand.png";
import wavingHand2x from "../img/common/wavingHand@2x.png";
import check from "../img/common/check.png";
import check2x from "../img/common/check@2x.png";
import stripe from "../img/common/stripe.png";
import stripe2x from "../img/common/stripe@2x.png";
import bottomWaves from "../img/common/bottomWaves.png";
import bottomWaves2x from "../img/common/bottomWaves@2x.png";
import purpleCard from "../img/common/purpleCard.png";
import purpleCard2x from "../img/common/purpleCard@2x.png";
import darkCard from "../img/common/darkCard.png";
import darkCard2x from "../img/common/darkCard@2x.png";
import lightCard from "../img/common/lightCard.png";
import lightCard2x from "../img/common/lightCard@2x.png";
import stars from "../img/common/stars.png";
import stars2x from "../img/common/stars@2x.png";
import logo from "../img/common/logo.png";
import logo2x from "../img/common/logo@2x.png";

const common = {
    wavingHand, wavingHand2x,
    check, check2x,
    stripe, stripe2x,
    bottomWaves, bottomWaves2x,
    purpleCard, purpleCard2x,
    darkCard, darkCard2x,
    lightCard, lightCard2x,
    stars, stars2x,
    logo, logo2x
}

// TODO change screenpattern in folder
const pt = {
    appleWallet: pt_appleWallet,
    appleWallet2x: pt_appleWallet2x,
    googleWallet: pt_googleWallet,
    googleWallet2x: pt_googleWallet2x,
    screenPattern: pt_screenPattern,
    screenPattern2x: pt_screenPattern2x,
    screenCard: pt_screenCard,
    screenCard2x: pt_screenCard2x,
    screenProfile: pt_screenProfile,
    screenProfile2x: pt_screenProfile2x,
}

// TODO change images in folder
const es = {
    appleWallet: es_appleWallet,
    appleWallet2x: es_appleWallet2x,
    googleWallet: es_googleWallet,
    googleWallet2x: es_googleWallet2x,
    screenPattern: es_screenPattern,
    screenPattern2x: es_screenPattern2x,
    screenCard: es_screenCard,
    screenCard2x: es_screenCard2x,
    screenProfile: es_screenProfile,
    screenProfile2x: es_screenProfile2x,
}

// TODO change images in folder
const en = {
    appleWallet: en_appleWallet,
    appleWallet2x: en_appleWallet2x,
    googleWallet: en_googleWallet,
    googleWallet2x: en_googleWallet2x,
    screenPattern: en_screenPattern,
    screenPattern2x: en_screenPattern2x,
    screenCard: en_screenCard,
    screenCard2x: en_screenCard2x,
    screenProfile: en_screenProfile,
    screenProfile2x: en_screenProfile2x,
}

const specific = navigator.language.includes('pt') ? pt : navigator.language.includes('es') ? es : en;
const img = { ...common, ...specific };

export default img;