import { useParams } from "react-router-dom";
import { useFetchEventQuery } from "../store";
import { Box, Button, Spinner } from "grommet";
import NiceError from "../components/NiceError";

import { useEffect, useState } from "react";

const Card = ({ fullname, companyName, username }) => {
    return (
        <Box className="label" justify="between" direction="row">
            <Box className="text">
                <h1>{fullname}</h1>
                <h2>{companyName}</h2>
            </Box>
            {username &&
                <img
                    className="qrcode"
                    src={"https://chart.googleapis.com/chart?chs=150x150&cht=qr&chl=https://www.nice2meet.io/valor/" + username}
                    alt="qrcode"
                    width="2.5cm"
                    height="2.5cm"
                />}
        </Box>
    )
}

const PageContent = () => {
    const { eventName } = useParams();
    const { data, error, isFetching, isLoading } = useFetchEventQuery(eventName);
    const [page, setPage] = useState(0);

    if (isFetching || isLoading) {
        return (
            <div className="A4">
                <section className="sheet">
                    <Spinner />
                </section>
            </div>
        )
    } else if (error) {
        return <Box fill="horizontal" align="center" justify="center"><NiceError /></Box>
    } else if (data) {
        let users = [...data]
        users.sort((a, b) => a.fullname.localeCompare(b.fullname));
        let groups = [];
        let temp = [];
        for (let i = 0; i < users.length; i++) {
            temp.push(users[i]);
            if (temp.length === 21 || i === users.length - 1) {
                groups.push(temp);
                temp = [];
            }
        }
        let maxPages = groups.length
        return (
            <div className="A4">
                <Box className="no-print" background="white" align="center" justify="center" pad="small">
                    <Box width="500px" direction="row" justify="between">
                        <Box basis="1/3">
                            <Button onClick={() => setPage(page - 1)} disabled={(page === 0)} label="Anterior" />
                        </Box>
                        <Box basis="1/3" align="center" justify="center">
                            <p>Página {page + 1} / {maxPages} </p>
                        </Box>
                        <Box basis="1/3">
                            <Button onClick={() => setPage(page + 1)} disabled={(page === maxPages - 1)} label="Próxima" />
                        </Box>
                    </Box>
                </Box>
                {groups.map((group, groupIndex) => {
                    if (groupIndex === page) {
                        return (
                            <section className="sheet" key={groupIndex}>
                                <Box className="container" direction="row" wrap>
                                    {group.map(({ fullname, companyName, username }, index) => (
                                        <Card key={index} fullname={fullname} companyName={companyName} username={username} />
                                    ))}
                                    {[...Array(21 - group.length)].map((index) => (
                                        <Card key={index} />
                                    ))}
                                </Box>
                            </section>
                        )
                    }
                })}
            </div>
        );
    };
}

const PrintFix = () => {
    useEffect(() => {
        let bootstrapLink = document.querySelector('link[href*="bootstrap"]');
        if (bootstrapLink) {
            bootstrapLink.parentNode.removeChild(bootstrapLink);
        }
        let newLink = document.createElement('link');

        newLink.setAttribute('rel', 'stylesheet');
        newLink.setAttribute('type', 'text/css');
        newLink.setAttribute('href', '/assets/css/A4360.css');

        document.head.appendChild(newLink);
    }, [])
    return (<></>)
}

const EventsQrCodes = () => {

    return (
        <>
            <PrintFix />
            <PageContent />
        </>
    )
}

export default EventsQrCodes;