import { useSelector } from "react-redux";
import { registerClick } from "./registeredClick";
import { Text } from "grommet";
import url from "../languages/url";

const DynamicPrice = () => {
    const { copy } = useSelector((state) => state.preference);

    const the_url = localStorage.getItem('url') || url.prices[5].url;
    const the_price = localStorage.getItem('price') || url.prices[5].price;

    const handleClick = () => {
        registerClick('checkout', 'banner_cta', copy.home.banner.label);
        registerClick('price', 'checkout_price_' + the_price.replace(/\D/g, ''), the_price);
    }

    return (
        <>
            <a href={the_url} className="button-4" onClick={handleClick} >{copy.home.banner.label}</a>
            <Text size="small" textAlign="center">{copy.home.banner.text} <s>R$ 99,99</s> {the_price}</Text>
        </>
    )
}

export default DynamicPrice;