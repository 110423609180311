import userCountry from "./userCountry"

const pt = {
    typeform: {
        feedback: "https://dmpv3xetrmt.typeform.com/to/mxq9UG26",
    }
}

// TODO translate
const es = {
    typeform: {
        feedback: "https://dmpv3xetrmt.typeform.com/to/mxq9UG26",
    }
}

// TODO translate
const en = {
    typeform: {
        feedback: "https://dmpv3xetrmt.typeform.com/to/mxq9UG26",
    }
}

const brazil = {
    stripe: {
        annual: {
            url: "https://buy.stripe.com/7sI8xI5J16vJ9m8fZ1",
            price: <>Crie o seu por apenas R$ 9,99<sup> ano</sup></>,
            cta: <>Crie seu cartão de visita virtual para Carteira Google ou Carteira Apple por apenas R$ 9,99<sup> ano</sup></>,
        }
    }
}

const not_brazil = {
    stripe: {
        annual: {
            url: "https://buy.stripe.com/00gdS21sL4nB7e09AE",
            price: <>Create your own for only US$ 9.99 <sup> year</sup></>,
            cta: <>Create your virtual business card for Google Wallet or Apple Wallet for only US$ 9.99<sup> year</sup></>,
        }
    }
}

const prices = [
    { price: "R$ 79,99", url: "https://buy.stripe.com/00g6pA2wP2ftfKw00g" },
    { price: "R$ 59,00", url: "https://buy.stripe.com/4gw9BMdbtf2f7e05kz" },
    { price: "R$ 39,99", url: "https://buy.stripe.com/14k01cc7p9HVeGsbIZ" },
    { price: "R$ 34,99", url: "https://buy.stripe.com/fZeeW60oH6vJ8i4aEW" },
    { price: "R$ 24,99", url: "https://buy.stripe.com/00g15gefxaLZbugcN0" },
    { price: "R$ 14,99", url: "https://buy.stripe.com/8wM7tE7R91bpfKw00d" },
]


const url_by_language = navigator.language.includes('pt') ? pt : navigator.language.includes('es') ? es : en;
const url_by_country = userCountry === "Brazil" ? brazil : not_brazil;

const url = { ...url_by_language, ...url_by_country, prices }

export default url;