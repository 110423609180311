import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const logsApi = createApi({
    reducerPath: "admin",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.NODE_ENV === "production" ? process.env.CLIENT : "http://localhost:3000"
    }),
    endpoints(builder) {
        return {
            logError: builder.mutation({
                query: (error, component) => {
                    return {
                        url: `/api/log`,
                        method: 'POST',
                        body: { 
                            status: error.status, 
                            component: component,
                            error: JSON.stringify(error.data)
                        }
                    }
                }
            }),
        }
    }
});

export const {
    useLogErrorMutation,
} = logsApi;
export { logsApi };