import { useLocation, useNavigate } from "react-router-dom";
import { Box, Select } from "grommet";
import logo2 from "../img/new/logo/logo2.svg"
import { setLanguage } from "../store";
import { useDispatch, useSelector } from "react-redux";
import { registerClick } from "./registeredClick";
import { useEffect } from "react";
import ReactGA from "react-ga4";

const HeaderSection = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const { copy, language } = useSelector((state) => state.preference);

    const sessionId = localStorage.getItem('sessionId');

    useEffect(() => {
        if (sessionId) {
            ReactGA.set({ userId: sessionId });
            registerClick('user', 'user_' + sessionId, null)
        }
    }, [sessionId])

    const handleChangeLanguage = (lan) => {
        dispatch(setLanguage(lan));
    }

    const cta = (!sessionId && pathname === '/') ?
        { label: copy.menu.start, action: () => window.open(copy.home.prices.annually[0].url, "_self") } :
        (sessionId) ?
            { label: copy.menu.profile, action: () => window.open('/links/' + sessionId, "_self") } :
            {}

    const menu = pathname !== '/' ?
        []
        : [
            {
                label: copy.menu.home,
                action: () => {
                    registerClick('menu', 'home', copy.menu.home);
                    document.getElementById('home').scrollIntoView({ behavior: 'smooth' })
                }
            },
            {
                label: copy.menu.howItWorks,
                action: () => {
                    registerClick('menu', 'how', copy.menu.howItWorks);
                    document.getElementById('how').scrollIntoView({ behavior: 'smooth' })
                }
            },
            {
                label: copy.menu.events,
                action: () => {
                    registerClick('menu', 'events', copy.menu.events);
                    document.getElementById('home').scrollIntoView({ behavior: 'smooth' });
                    navigate('/events')
                }
            }
        ]

    return (
        <header className="header-section header-cl-black active" style={{ zIndex: 19 }}>
            <div className="container">
                <div className="header-wrapper">
                    <div className="logo">
                        <a onClick={() => window.open('/', "_self")}>
                            <img src={logo2} alt="logo" />
                        </a>
                    </div>
                    <ul className="menu">
                        {menu.map(({ label, action }, i) => (
                            <li key={i}>
                                <a onClick={action}>{label}</a>
                            </li>
                        ))}
                        {cta.action &&
                            <li className="d-sm-none">
                                <a className="m-0 header-button" onClick={cta.action} style={{ textTransform: "revert" }}>{cta.label}</a>
                            </li>
                        }
                    </ul>
                    <Box className="header-right" direction="row">
                        <Select
                            options={['pt', 'en', 'es']}
                            value={language}
                            onChange={({ option }) => handleChangeLanguage(option)}
                            size="xsmall"
                            width="40px"
                            plain
                        />
                        {cta.action &&
                            <a href="#go" className="header-button d-sm-inline-block no-wrap" onClick={cta.action} style={{ color: "#3b368c", textTransform: "revert" }}>{cta.label}</a>
                        }
                    </Box>
                </div>
            </div>
        </header >
    )
}

export default HeaderSection;