import { useSelector } from "react-redux";
import icon from "../../../img/new/icons/events-cta.png";

const EventsCTA = ({ mailto }) => {
    const { copy } = useSelector((state) => state.preference);

    return (
        <section className="cost-section padding-bottom padding-top mt-max-xl-0">
            <div className="container">
                <div className="cost-wrapper text-center">
                    <div className="cost-icon">
                        <div className="icon">
                            <img src={icon} alt="cost" />
                        </div>
                    </div>
                    <div className="section-header mb-olpo">
                        <h5 className="cate">{copy.events.cta.subtitle}</h5>
                        <h3 className="title">{copy.events.cta.title}</h3>
                        <p>
                            {copy.events.cta.paragraph}
                        </p>
                    </div>
                    <a href={mailto} className="button-2 long-light active">{copy.events.cta.cta}</a>
                </div>
            </div>
        </section>
    )
}

export default EventsCTA;