import React from 'react';
import OwlCarousel from 'react-owl-carousel2';

import company01 from "../img/new/companies/company01.png";
import company02 from "../img/new/companies/company02.png";
import company03 from "../img/new/companies/company03.png";
import company04 from "../img/new/companies/company04.png";
import company05 from "../img/new/companies/company05.png";
import company06 from "../img/new/companies/company06.png";
import { useSelector } from "react-redux";

const LogoSliderSection = () => {
    const companies = [company01, company02, company03, company04, company05, company06]
    const { copy } = useSelector((state) => state.preference);

    return (
        <section className="logo-slider-section section-bg padding-bottom padding-top d-lg-block d-none">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10 text-center mb-4 mb-lg-5">
                        <h5 className="title">{copy.home.clients.title}</h5>
                    </div>
                </div>
                <OwlCarousel
                    options={{
                        items: 6,
                        nav: false,
                        rewind: true,
                        autoplay: true,
                        loop: true,
                        autoplayTimeout: 1500,
                    }}
                >
                    {companies.map((company, i) => (
                        <div className="item" key={i}>
                            <img src={company} alt="banner" width="100px" heigh="100px" style={{ width: "100px", height: "100px" }} />
                        </div>
                    ))}
                </OwlCarousel>
            </div>
        </section >
    )
}

export default LogoSliderSection;