import { createSlice } from '@reduxjs/toolkit';
import { pt, en, es } from "../../languages/copy";

const savedSeen = localStorage.getItem('seen');
const haveSeen = !savedSeen || !(Array.isArray(savedSeen) && savedSeen[0]);

const preferenceSlice = createSlice({
    name: 'preference',
    initialState: {
        language: "pt",
        copy: en,
        seen: haveSeen ? JSON.parse(savedSeen) : null
    },
    reducers: {
        setLanguage(state, action) {
            state.language = action.payload;
            state.copy = action.payload.includes('pt') ? pt : action.payload.includes('es') ? es : en;
            localStorage.setItem('language', action.payload);
        },
        setSeen(state, action) {
            if (haveSeen) {
                if (state.seen) {
                    if (state.seen.indexOf(action.payload) === -1) {
                        state.seen.push(action.payload);
                        localStorage.setItem('seen', JSON.stringify(state.seen));
                    };
                } else {
                    state.seen = [action.payload];
                    localStorage.setItem('seen', JSON.stringify(state.seen));
                }
            }
        },
    }
})

export default preferenceSlice.reducer
export const {
    setLanguage,
    setSeen
} = preferenceSlice.actions