import OwlCarousel from 'react-owl-carousel2';
import { Box } from "grommet";

import background from "../img/new/backgrounds/feature.png";
import screenshot from "../img/new/screenshots/feature.png";
import icon from "../img/new/icons/feature.png";
import { useSelector } from "react-redux";

const FeatureSection = () => {
    const { copy } = useSelector((state) => state.preference);

    return (
        <section className="feature-section ovelflow-hidden padding-bottom padding-top">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-9">
                        <div className="section-header mw-100">
                            <h5 className="cate">{copy.home.features.subtitle}</h5>
                            <h2 className="title">{copy.home.features.title}</h2>
                            <p className="mx-auto mw-540px">
                                {copy.home.features.paragraph}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="feature-wrapper-20">
                    <Box className="feature-wrapper-bg-20 bg_img" style={{ backgroundImage: "url(" + background + ")" }}>
                        <div className="row align-items-center">
                            <div className="col-lg-6 d-none d-lg-block">
                                <div className="feature-thumb-20 rtl">
                                    <img src={screenshot} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="feature-content-20 cl-white">
                                    <OwlCarousel
                                        options={{
                                            items: 1,
                                            nav: false,
                                            rewind: true,
                                            autoplay: true,
                                            dots: false
                                        }}
                                        style={{ minHeight: "85px" }}
                                    >
                                        {copy.home.features.carousel.map(([title, paragraph], i) => (
                                            <div className="feature-content-item-20" key={i}>
                                                <div className="feature-content-icon-20">
                                                    <img src={icon} alt="extra-2/feature" />
                                                </div>
                                                <h3 className="title">
                                                    {title}
                                                </h3>
                                                <p>
                                                    {paragraph}
                                                </p>
                                            </div>
                                        ))}
                                    </OwlCarousel>
                                    {/* <div className="feat-nav owl-nav mt-4">
                                        <a href="#go" className="owl-prev" ><i className="flaticon-left"></i></a>
                                        <a href="#go" className="owl-next active"><i className="flaticon-right"></i></a>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </Box>
                </div>
            </div>
        </section>
    )
}

export default FeatureSection;