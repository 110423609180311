import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const adminApi = createApi({
    reducerPath: "admin",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.NODE_ENV === "production" ? process.env.CLIENT : "http://localhost:3000"
    }),
    endpoints(builder) {
        return {
            updateMailing: builder.mutation({
                query: () => {
                    return {
                        url: `/api/admin/update_mailing`,
                        method: 'GET',
                    }
                }
            }),
        }
    }
});

export const {
    useUpdateMailingMutation,
} = adminApi;
export { adminApi };