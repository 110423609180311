import { Box, Heading, Layer } from "grommet";
import { useState } from "react";
import { useSelector } from "react-redux";

const PromoLayer = ({ buyButtonId }) => {
    const [show, setShow] = useState(true);
    const { copy } = useSelector((state) => state.preference);

    return (
        <>
            {show &&
                <Layer
                    onEsc={() => setShow(false)}
                    onClickOutside={() => setShow(false)}

                >
                    <Box
                        size="medium"
                        margin="small"
                        pad="large"
                        align="center"
                        justify="center"
                    >
                        <Heading level={3}>Você foi convidado para essa promoção</Heading>
                        <br/>
                        <stripe-buy-button
                            buy-button-id={buyButtonId}
                            publishable-key="pk_live_51MKkAtH5eAYi3bVUBnpsGOx5XeVoffXfCkTib4yPb5airBmLrBxsXDv8pZ7hghr4n1fZq2uiPaepxyvpwkgKd2V400zfySrMlg"
                        >
                        </stripe-buy-button>
                    </Box>
                </Layer >}
        </>
    )
}

export default PromoLayer;