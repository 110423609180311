import { useSelector } from "react-redux";
import illustration from "../img/new/illustrations/tips.png";

const Tips = ({ border, ...props }) => {
    const { copy } = useSelector((state) => state.preference);

    return (
        <section className="faq-section padding-top" {...props}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="faq-header">
                            <div className="cate">
                                <img src={illustration} alt="cate" />
                            </div>
                            <h2 className="title">{copy.tips.title}</h2>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="faq-wrapper mb--38">
                            {copy.tips.tips.map((tip, i) => (
                                <div className="faq-item" key={i}>
                                    <div className={"faq-thumb " + border}>
                                        <i className="fas fa-address-card"></i>
                                    </div>
                                    <div className="faq-content">
                                        <h4 className="title">{tip.title}</h4>
                                        {tip.paragraphs.map((paragraph, i) => <p key={i}>{paragraph}</p>)}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Tips;