import OwlCarousel from 'react-owl-carousel2';

import icon01 from "../img/new/icons/how01.png";
import icon02 from "../img/new/icons/how02.png";
import icon03 from "../img/new/icons/how03.png";
import how02 from "../img/new/screenshots/how02.png";
import { useSelector } from "react-redux";
import { registerClick } from "./registeredClick";

const HowSection = () => {
    const { copy } = useSelector((state) => state.preference);
    const handleClick = () => {
        registerClick('checkout', 'how_cta', copy.home.how.subscribe);
        window.open(copy.home.prices.annually[0].url, "_self");
    } 

    return (
        <section className="how-section-20 ovelflow-hidden" id="how">
            <div className="container">
                <div className="row align-items-end">
                    <div className="col-lg-4 col-xl-3">
                        <div className="move-top--lg-70">
                            <div className="section-header left-style mb-olpo">
                                <h5 className="cate">{copy.home.how.subtitle}</h5>
                                <h2 className="title">{copy.home.how.title}</h2>
                                <p>
                                    {copy.home.how.paragraph}
                                </p>
                            </div>
                            <div className="how-item-wrapper-20">
                                <div className="how-item-20"
                                    onClick={handleClick}
                                    style={{ cursor: "pointer" }}
                                >
                                    <h6 className="title">{copy.home.how.subscribe}</h6>
                                    <div className="how-thumb">
                                        <img src={icon01} alt="how" />
                                    </div>
                                </div>
                                <div className="how-item-20">
                                    <h6 className="title">{copy.home.how.configure}</h6>
                                    <div className="how-thumb">
                                        <img src={icon02} alt="how" />
                                    </div>
                                </div>
                                <div className="how-item-20">
                                    <h6 className="title">{copy.home.how.download}</h6>
                                    <div className="how-thumb">
                                        <img src={icon03} alt="how" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-xl-9">
                        <div className="how-slider-wrapper mt-4 mt-lg-0 ml--lg-78 mr--lg-65">
                            <OwlCarousel
                                options={{
                                    items: 1,
                                    nav: false,
                                    rewind: true,
                                    autoplay: true,
                                    dots: false,
                                    animateIn: true,
                                    animateOut: true,
                                    transitionStyle: "fade",
                                    autoplayTimeout: 1500,
                                    loop: true
                                }}
                            >
                                <div className="thumbs">
                                    <img src={how02} alt="extra-2/feature" />
                                </div>
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HowSection;